import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
// import i18n from '../../translation/i18n';
import './DropdownProfileLanguage.css';

interface PropsDropdownProfileLanguage {
    miniSize: boolean,
}

const DropdownProfileLanguage: FC<PropsDropdownProfileLanguage> = (props: PropsDropdownProfileLanguage) => {
    const friendOptions = !props.miniSize ? [
        // {
        //     key: 'en',
        //     text: 'English',
        //     value: 'en'
        // },
        {
            key: 'vn',
            text: 'Vietnam',
            value: 'vn'
        },
        {
            key: 'jp',
            text: 'Japan',
            value: 'jp'
        }
    ] : [
        // {
        //     key: 'en',
        //     value: 'en',
        //     flag: 'gb eng'
        // },
        {
            key: 'vn',
            value: 'vn',
            flag: 'vn'
        },
        {
            key: 'jp',
            value: 'jp',
            flag: 'jp'
        }
    ]

    const changeLanguage = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
        // event.preventDefault();
        i18n.changeLanguage(data.value);
    };

    const { i18n } = useTranslation();

    return (
        <>
            {
                !props.miniSize ? (<Dropdown

                    button
                    className='icon'
                    floating
                    labeled
                    icon='world'

                    defaultValue={'jp'}
                    options={friendOptions}
                    onChange={changeLanguage}
                />) : (<Dropdown

                    button
                    className='icon dropdown-language'
                    floating
                    icon={null}

                    defaultValue={i18n.language}
                    options={friendOptions}
                    onChange={changeLanguage}
                />)
            }
        </>
    )
}

export default DropdownProfileLanguage