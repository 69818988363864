import React, { FC, Fragment, useEffect, useState } from "react";
import { Image, Grid, Header, Form, Button, Message } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import bgImage from "../../../assets/bg-login.jpg";
import "./Login.css";
import { useHistory, useParams } from "react-router-dom";
import FirebaseAuthService from "../../../services/FirebaseAuthService";
import { useAppDispatch } from "../../../redux/store";
import {selectRestaurantPermission, selectUserIsLogin, setPhotoURL, setUserLogin} from "../../../redux/userSlice";
import { useSelector } from "react-redux";
import DropdownProfileLanguage from "../../layouts/DropdownProfileLanguage";
import FirebaseFirestoreService from "../../../services/FirebaseFirestoreService";



const Login: FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const { t } = useTranslation();
    const dispatchReduxToolkit = useAppDispatch();
    const isLoggedIn = useSelector(selectUserIsLogin);
    const getPermission = useSelector(selectRestaurantPermission);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const isUseIframe = history.location.pathname === '/login-iframe';
    


    document.title = t("login.title");


    const onChange = (e: any) => {
        if (e.target.type === "password") {
            setPassword(e.target.value);
        } else {
            setEmail(e.target.value);
        }
    };

    useEffect(() => {
        if(isLoggedIn && isUseIframe){
            const previousLocation: any = history.location.state
            history.push(previousLocation.from.pathname);
            return
        }else{
            if(isLoggedIn && getPermission === 0) {
                history.push("/");
            }else if(isLoggedIn) {
                history.push("/home");
            }
        }
        
    }, [isLoggedIn])

    const loginHandler = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        if (!email || !password) {
            setError(true);
            setIsLoading(false);
        } else {
            setError(false);
            try {
                const res = await FirebaseAuthService.loginUser(email, password);
                const userDetail = await FirebaseFirestoreService.getUserDetail(res.user.uid);
                if (userDetail && userDetail.is_active) {
                    // if (userDetail.permission !== 0) {
                    //     const imgProfile = await FirebaseFirestoreService.getRestaurantDetail(userDetail.id_restaurant);
                    //     dispatchReduxToolkit(setPhotoURL(imgProfile.data()));
                    // }
                    dispatchReduxToolkit(setUserLogin(userDetail));
                    setIsLoading(false);
                } else {
                    setError(true);
                    setIsLoading(false);
                }
            } catch {
                setError(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <Fragment>
            <Grid columns={2} padded stackable>
                <Grid.Row className="row-login-container">
                    <Grid.Column textAlign="center" verticalAlign="middle" className="grid-login-form column-login">
                        <div style={{ maxWidth: 450, minWidth: 300 }}>
                            <Header as='h2' textAlign='center'>
                                {t("login.header")}
                            </Header>
                            <Form size='large' onSubmit={loginHandler}>
                                <Form.Input fluid icon='user' iconPosition='left' placeholder={t("login.email_hint")} onChange={onChange} error={error} />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder={t("login.password_hint")}
                                    type='password'
                                    onChange={onChange}
                                    error={error}
                                />

                                <Button fluid primary size='large' loading={isLoading} onClick={loginHandler}>
                                    {t("login.login_btn")}
                                </Button>
                                <Message
                                    visible={error}
                                    error
                                    content={t("login.message_error")}
                                />
                                <div className="home-dropdown-language">
                                    <DropdownProfileLanguage miniSize={false} />
                                </div>
                            </Form>
                        </div>
                    </Grid.Column>
                    <Grid.Column only="tablet computer" textAlign="center" verticalAlign="middle">
                        <Image src={bgImage} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default Login;