import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectUserIsLogin } from '../redux/userSlice';

const NotFoundRouter = () => {

    const isLoggedIn = useSelector(selectUserIsLogin);

    return (
        <>
            {!isLoggedIn ?
                <Redirect to={{ pathname: '/login' }} />
                :

                <Redirect
                    to={{ pathname: '/exception?type=404' }}
                />
            }
        </>
    );
}
export default NotFoundRouter;