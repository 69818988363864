const  convertImageUrl = (originalUrl: string, fileName?: string): string => {
    if(fileName){
        return originalUrl.replace(fileName, `${fileName}_500x500`)
    }else{
        const splitUrl = originalUrl.split(".");
        const fileExtension = splitUrl.pop();
        const modifiedUrl = `${splitUrl.join(".")}_500x500.${fileExtension}`;
        return modifiedUrl;
    }
}

export {convertImageUrl}