import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';


interface IProtectRouteProps extends RouteProps {
    isSignIn: boolean | undefined,
    permission: number,
    role: number,
    isUseIframe?: boolean
}


const PrivateRoute: React.FC<IProtectRouteProps> =
    ({ component: Component, isSignIn, permission, role, isUseIframe = false, ...rest }) => {
        return (
            <Route {...rest} render={props => {

                if (!isSignIn) {

                    return <Redirect to={{ pathname: isUseIframe ? '/login-iframe' :'/login', state: { from: props.location } }} />
                }

                if (role !== 0 && permission !== role && !isUseIframe) {
                    return <Redirect to={{
                        pathname: '/home',
                        state: { from: props.location },
                    }} />
                }

                return Component ? <Component {...props} /> : <Redirect to={{
                    pathname: '/exception?type=401',
                    state: { from: props.location },
                }} />;
            }
            }
            />

        );
    }
export default PrivateRoute;