import { StylesConfig } from "react-select";

export interface ColourOption {
  readonly value: number;
  readonly label: string;
  readonly isChild?: boolean;
}

export const colourStyles: any = {
  // control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: any, { data }: any) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      paddingLeft: data.isChild ? "2rem" : "",
      ":before": {
        content: data.isChild ? '" - "' : '""',
      },
    };
  },
};
