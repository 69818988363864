import { Timestamp } from "firebase/firestore";

export default class Utils {
  static getLocalDateTime(serverTimestamp: any) {
    return (
      new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000).toLocaleDateString() +
      " " +
      new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000).toLocaleTimeString()
    );
  }

  static getLocalDateTimeByFormat(serverTimestamp: any, format: string) {
    const date = new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000);

    // Helper function to pad zeros
    const padZero = (num: number) => {
      return num.toString().padStart(2, "0");
    };

    // Extract date and time components
    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
    const meridian = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert to 12-hour format

    // Replace format placeholders with respective values
    format = format.replace("dd", day);
    format = format.replace("MM", month);
    format = format.replace("yyyy", year.toString());
    format = format.replace("hh", padZero(hours));
    format = format.replace("HH", hours.toString()); // If you want to keep the 24-hour format as well
    format = format.replace("mm", minutes);
    format = format.replace("ss", seconds);
    format = format.replace("vv", meridian);

    return format;
  }

  static getLocalTime(serverTimestamp: any) {
    try {
      return new Date((serverTimestamp.seconds | serverTimestamp._seconds) * 1000).toLocaleTimeString();
    } catch {
      return "--:--:--";
    }
  }

  static toNumber(data: any) {
    const res = Number(data);
    if (isNaN(res)) return 0;
    return res;
  }

  static validJapanese = (str: string) => {
    const containsJapanese = str.match(
      /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/
    );

    if (containsJapanese) {
      return str.length * 2;
    } else {
      return new Blob([str]).size;
    }
  };
}

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const numberWithCommas = (number: any) => {
  try {
    return number ? "¥" + Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "¥ 0";
  } catch {
    return number ? "¥" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "¥ 0";
  }
};

export const numberThousandsCommas = (number: any) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const countBreakLine = (textAreaValue: any) => {
  if (!textAreaValue) return;

  let numberOfLineBreaks = (textAreaValue.match(/\n/g) || []).length;

  return numberOfLineBreaks;
};

export const isEmptyArray = (arr: any[]) => {
  return Array.isArray(arr) && arr.length === 0;
}

export const createKeywords = (name: string) => {
  const arrName: any = [];
  let curName = '';
  name.split('').forEach(letter => {
    curName += letter;
    arrName.push(curName);
  });
  return arrName;
}