import React from "react";
import { Loader } from "semantic-ui-react";
import "./Loading.css";

const Loading = () => {
    return (
        // <div className="circles">
        //     <div className="circle1"></div>
        //     <div className="circle2"></div>
        //     <div className="circle3"></div>
        // </div>
        <Loader size='massive' active>Loading</Loader>
    )
}

export default Loading;