import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../model/user";
import { RootState } from "./store";

const initialState: User = {
    uid: "",
    email: "",
    displayName: "",
    is_login: false,
    photoURL: "",
    is_active: false,
    permission: -1,
    id_restaurant: "",
    ref_restaurant: null
};

export const userSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setUserLogin: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                uid: action.payload.uid,
                email: action.payload.email,
                displayName: action.payload.displayName,
                photoURL: action.payload.photoURL,
                is_login: true,
                is_active: action.payload.is_active,
                permission: action.payload.permission,
                id_restaurant: action.payload.permission === 0 ? "" : action.payload.id_restaurant,
                ref_restaurant: action.payload.ref_restaurant
            }
        },
        setRestaurantId: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                id_restaurant: action.payload.id
            }
        },
        setPhotoURL: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                photoURL: action.payload
            }
        },
        setUserLogout: state => {
            return {
                ...initialState
            }
        },
    }
})

export const selectuser = (rootState: RootState) => rootState.user;
export const selectUserIsLogin = (rootState: RootState) => selectuser(rootState).is_login;
export const selectRestaurantId = (rootState: RootState) => selectuser(rootState).id_restaurant;
export const selectRestaurantPermission = (rootState: RootState) => selectuser(rootState).permission;
export const { setUserLogin, setUserLogout, setPhotoURL, setRestaurantId } = userSlice.actions;
export default userSlice.reducer;