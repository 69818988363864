import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface TempState {
    id: string,
    ciamId: string,
    firstName: string,
    lastName: string,
    customerId: number,
    phoneNumber: string,
    mobilePhone: string,
    email: string
}

export const initialState: TempState = {
    id: '',
    ciamId: '',
    firstName: '',
    lastName: '',
    customerId: -1,
    phoneNumber: '',
    mobilePhone: '',
    email: ''
};



export const selectTicketState = (rootState: RootState) => rootState.temp;

const tempReducerSlice = createSlice({
    name: 'ticketReducer',
    initialState: initialState,
    reducers: {
        //getTicketState: state => { return { ...state } }
    }
});

//export const { getTicketState } = ticketReducerSlice.actions;
export default tempReducerSlice.reducer;