import { useState, useEffect } from "react";
import DropdownOps from "../model/dropdownOps";
import FirebaseFirestoreService from "../services/FirebaseFirestoreService";


export default function useRestaurant() {
    const [listRest, setListRest] = useState<DropdownOps[]>([]);

    useEffect(() => {
        const unsubscribe = FirebaseFirestoreService.streamRestaurantListItems(
            (querySnapshot: any) => {
                const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
                    key: doc.id,
                    text: doc.data().name,
                    value: doc.id,
                }));
                setListRest(updatedGroceryItems);
            },
            (error: any) => console.log("grocery-list-item-get-fail")
        );
        return unsubscribe;
    }, [setListRest]);

    return [listRest]
}